<template>
    <highcharts  class="h-full w-full" :options="options" :ref="chartId" :id="chartId" />
</template>

<script>
    import {reduceBigNumbers} from "../main";

    let gradients = [
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [[0, '#FE694E'], [1, '#F7CB85']]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [[0, '#6511EB'], [1, '#11B0EB']]
        }
    ]

    export default {
        props: ['data','chartId','categories','small','reflow', 'mobile'],
        data: function() {
            return {
                options: {
                    chart: {
                        type: 'spline',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingTop: 10,
                    },
                    defs: {
                        glow: {
                            tagName: 'filter',
                            id: 'glow',
                            opacity: 0.5,
                            children: [
                                {
                                tagName: 'feGaussianBlur',
                                result: 'coloredBlur',
                                stdDeviation: 2.5
                                },
                                {
                                tagName: 'feMerge',
                                children: [
                                    {
                                    tagName: 'feMergeNode',
                                    in: 'coloredBlur'
                                    },
                                    {
                                    tagName: 'feMergeNode',
                                    in: 'SourceGraphic'
                                    }
                                ]
                            }]
                        }
                    },
                    title: { text: '' },
                    subtitle: { text: '' },
                    credits: { enabled: false },
                    xAxis: [{
                        type: 'datetime',
                        //categories:this.categories,
                        dateTimeLabelFormats: {
                            month: '%b',
                        },
                        lineColor: 'rgba(255, 255, 255, 0)',
                        title: { text: null },
                        labels: {
                            enabled: true,
                            style: { color:'#768194',fontSize: this.mobile ? '7px' : '8px' }
                        },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 30 
                    }],
                    exporting: { enabled:false },
                    yAxis: {
                        title: { text: '' },
                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: false,
                            style: { color:'#768194' },
                        }
                    },
                    tooltip: {
                        enabled: true,
                        formatter: function () {
                            return reduceBigNumbers(this.y) + ' <small>h.</small>';
                        }
                    },
                    plotOptions: {
                       spline: {
                             marker: {
                                enabled: false
                            },
                        },
                        series: {
                            borderRadius: 3,
                            shadow: {
                                color: 'rgba(0, 0, 0, .6)',
                                offsetX: 0,
                                offsetY: 1,
                                width: 4
                            },
                        }
                    },
                    legend: { enabled: false },
                    series: [ ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                var i = 0
                for (var serie in this.data) {
                    let getSerie = chart.get(serie);
                    if (getSerie) { chart.series[i].setData(this.data[serie]) }
                    else {
                        chart.addSeries({ 
                            color: gradients[i],
                            id: serie,
                            name: serie,
                            data: this.data[serie],
                        })
                    }
                    i++;
                }
            },
        },
        watch: {
            data: {
                handler(a, b) {
                    this.load();
                },
                deep: true
            },
            reflow(n,o) {
                let chart = this.$refs[this.chartId].chart;
                chart.reflow()
            }
        },
        mounted() {
            this.load();
        }
    };
</script>
