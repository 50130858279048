export default function mediaType(mediaCards) {
    let more_news  = []
    let lastest = []
    let competence = []
    let economy_lifestyle = []
    let i = 0;
    for (let elem in mediaCards) {
        if (mediaCards[i].section === 'more_news') {
            more_news.push(mediaCards[i])
        }
        if (mediaCards[i].section === 'lastests' || mediaCards[i].section === 'lastest') {
            lastest.push(mediaCards[i])
        }
        if (mediaCards[i].section === 'competence') {
            competence.push(mediaCards[i])
        }
        if (mediaCards[i].section === 'economy_lifestyle') {
            economy_lifestyle.push(mediaCards[i])
        }
        i++;
    }
    return {more_news, lastest, competence, economy_lifestyle}
}