export default (authGuard, guestGuard) => [
    ...guestGuard([
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/Login')
        }

    ]),

    ...authGuard([
        // luego pasar al authGuard
        {
            path: '/',
            name: 'root',
            redirect: {name:'home'}
        },
        {
            path: '/mobile',
            name: 'mobile',
            component: () =>{ return import('@/layouts/app') },
            children: [
                {
                    path: 'home',
                    name: 'homeMobile',
                    component: () => import('@/pagesMobile/Home')
                },
                {
                    path: 'pipeline',
                    name: 'pipelineMobile',
                    component: () => import('@/pagesMobile/Pipeline'),
                    redirect: {name: 'PipelineHomeMobile'},
                    children:[
                        {
                            path: 'PipelineHomeMobile',
                            name: 'PipelineHomeMobile',
                            component: () => import('@/pagesMobile/Pipeline/PipelineHomeMobile')
                        },
                        {
                            path: 'SLSMU',
                            name: 'SLSMU',
                            component: () => import('@/pagesMobile/Pipeline/SLSMU')
                        },
                        {
                            path: 'Circles',
                            name: 'Circles',
                            component: () => import('@/pagesMobile/Pipeline/Circles')
                        },
                        {
                            path: 'Opportunities',
                            name: 'Opportunities',
                            component: () => import('@/pagesMobile/Pipeline/Opportunities')
                        }
                    ]
                },
                {
                    path: 'PersonalDashboard',
                    name: 'PersonalDashboardMobile',
                    component: () => { return import('@/pagesMobile/PersonalDashboard') },
                    redirect: {name:'HomePersonalDashboard'},
                    children: [

                        {
                            path: 'HomePersonalDashboard',
                            name: 'HomePersonalDashboard',
                            component: () => import('@/pagesMobile/PersonalDashboard/HomePersonalDashboard')
                        },
                        {
                            path: 'TheEssential/:id?',
                            name: 'TheEssentialMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/TheEssential')
                        },
                        {
                            path: 'Alerts',
                            name: 'AlertsMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/Alerts')
                        },
                        {
                            path: 'TalkingPoints',
                            name: 'TalkingPointsMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/TalkingPoints')
                        },
                        {
                            path: 'Media/:id?',
                            name: 'MediaMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/Media')
                        },
                        {
                            path: 'Events/:id?',
                            name: 'EventsMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/Events')
                        },
                        {
                            path: 'Wins',
                            name: 'WinsMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/Wins')
                        },
                        {
                            path: 'CommonsPodcasts',
                            name: 'CommonsPodcastsMobile',
                            component: () => import('@/pagesMobile/PersonalDashboard/CommonsPodcasts')
                        },
                        {
                            path: 'Tipsanddocs',
                            name: 'Tipsanddocs',
                            component: () => import('@/pagesMobile/PersonalDashboard/Tipsanddocs')
                        }

                    ]
                },
                {
                    path: 'ProjectsManagement',
                    name: 'ProjectsManagement',
                    component: () => import('@/pagesMobile/ProjectsManagement'),
                    redirect: {name:'ProjectsManagementHomeMobile'},
                    children:[
                        {
                            path: 'ProjectsManagementHomeMobile',
                            name: 'ProjectsManagementHomeMobile',
                            component: () => import('@/pagesMobile/ProjectsManagement/ProjectsManagementHomeMobile')
                        },
                        {
                            path: 'projectsManagementSLSMU',
                            name: 'projectsManagementSLSMU',
                            component: () => import('@/pagesMobile/ProjectsManagement/SLSMU')
                        },
                        {
                            path: 'ProjectsProjects',
                            name: 'ProjectsProjects',
                            component: () => import('@/pagesMobile/ProjectsManagement/ProjectsProjects')
                        },
                        {
                            path: 'ProjectsProjects/:id',
                            name: 'ProjectsDetail',
                            component: () => import('@/pagesMobile/ProjectsManagement/ProjectsDetail')
                        },
                        {
                            path: 'ProjectsProjects/TnrMobile',
                            name: 'TnrMobile',
                            component: () => import('@/pagesMobile/ProjectsManagement/TnrMobile')
                        }
                    ]
                },
                {
                    path: 'Chargeability',
                    name: 'Chargeability',
                    component: () => import('@/pagesMobile/Chargeability'),
                    redirect:{name:'SLSMUChargeability'},
                    children:[
                        {
                            path: 'SLSMUChargeability',
                            name: 'SLSMUChargeability',
                            component: () => import('@/pagesMobile/Chargeability/SLSMUChargeability')
                        },
                        {
                            path: 'Smus',
                            name: 'Smus',
                            component: () => import('@/pagesMobile/Chargeability/Smus')
                        }
                    ]
                },
                {
                    path: 'InterIntra',
                    name: 'InterIntraMobile',
                    component: () => import('@/pagesMobile/InterIntra'),
                    redirect:{name:'SwipSLSMU'},
                    children: [
                        {
                            path: 'SwipSLSMU',
                            name: 'SwipSLSMU',
                            component: () => import('@/pagesMobile/InterIntra/SwipSLSMU')
                        },
                        // {
                        //     path: 'InterIntraMobileSL',
                        //     name: 'InterIntraMobileSL',
                        //     component: () => import('@/pagesMobile/InterIntra/SL')
                        // },
                        // {
                        //     path: 'InterIntraMobileSMU',
                        //     name: 'InterIntraMobileSMU',
                        //     component: () => import('@/pagesMobile/InterIntra/SMU')
                        // },
                        {
                            path: 'InterIntraMobileGraph/:catId/:idSL',
                            name: 'InterIntraMobileGraph',
                            component: () => import('@/pagesMobile/InterIntra/graph')
                        }
                    ]
                },
                {
                    path: 'Accept',
                    name: 'Accept',
                    component: () => import('@/pagesMobile/Accept')
                }

            ]
        },

        {
            path: '/desktop',
            name: 'desktop',
            meta: { breadCrumb: '<i class="mdi mdi-home"></i>' },
            redirect: {name:'home'},
            component: () => { return import('@/layouts/fullscreen') },
            children: [

                {
                    path: 'home',
                    name: 'home',
                    meta: { breadCrumb: 'Summary ' },
                    component: () => import('@/pages/Home'),
                },
                {
                    path: 'PipelineSLSMU',
                    name: 'PipelineSLSMU',
                    meta: { breadCrumb: 'Pipeline SL/SMU ' },
                    component: () => import('@/pages/Pipeline/PipelineSLSMU')
                },
                {
                    path: 'ProjectsSLSMU',
                    name: 'ProjectsSLSMU',
                    meta: { breadCrumb: 'Projects SL/SMU ' },
                    component: () => import('@/pages/ProjectsSLSMU')
                },
                {
                    path: 'OpportunitiesList',
                    name: 'OpportunitiesList',
                    meta: { breadCrumb: 'Opportunities list' },
                    component: () => { return import('@/pages/Pipeline/OpportunitiesList') },
                },
                {
                    path: 'OpportunitiesList/:catId',
                    name: 'OpportunitiesListCat',
                    meta: { breadCrumb: 'Opportunities list' },
                    component: () => import('@/pages/Pipeline/OpportunitiesList')
                },
                {
                    path: 'OpportunitiesStatus',
                    name: 'OpportunitiesStatus',
                    component: () => import('@/pages/OpportunitiesStatus')
                },
                {
                    path: 'ChargeabilitySls',
                    name: 'ChargeabilitySls',
                    component: () => import('@/pages/ChargeabilitySls')
                },
                {
                    path: 'Tnr',
                    name: 'TNR',
                    component: () => import('@/pages/Tnr')
                },
                {
                    path: 'ProjectsBilling',
                    name: 'ProjectsBilling',
                    meta: { breadCrumb: 'Projects Billing' },
                    component: () => import('@/pages/ProjectsBilling')
                },
                {
                    path: 'ProjectsBilling/:projectId',
                    name: 'ProjectsBillingChild',
                    meta: { breadCrumb: 'Projects Billing' },
                    component: () => import('@/pages/ProjectsBilling')
                },
                {
                    path: 'InterIntra',
                    name: 'InterIntra',
                     meta: { breadCrumb: 'Interoperabilidad' },
                    component: () => { return  import('@/pages/InterIntra') },
                },
                {
                    path: 'InterIntra/:catId/:idSL',
                    name: 'InterIntraExtend',
                    meta: { breadCrumb: 'Inter / Intra extend' },
                    component: () => { return import('@/pages/InterIntra/InterIntraExtend') }
                },

                {
                    path: 'PersonalDashboard',
                    name: 'PersonalDashboard',
                    redirect: { name: 'TheEssential' },
                    component: () =>  { return import('@/pages/PersonalDashboard') },
                    children: [
                        // {
                        //     path: 'LastUpdates',
                        //     name: 'LastUpdates',
                        //     meta: { catName: 'Last Updates', icon:'view-grid' },
                        //     component: () =>  import('@/pages/PersonalDashboard/LastUpdates')
                        // },
                        {
                            path: 'TheEssential/:id?',
                            name: 'TheEssential',
                            meta: { catName: 'The Essential', icon:'calendar-star' },
                            component: () =>  import('@/pages/PersonalDashboard/TheEssential')
                        }, {
                            path: 'Alerts',
                            name: 'Alerts',
                            meta: { catName: 'Alerts', icon:'message-alert-outline' },
                            component: () =>  import('@/pages/PersonalDashboard/Alerts')
                        },{
                            path: 'TalkingPoints',
                            name: 'TalkingPoints',
                            meta: { catName: 'Talking points & Firm docs', icon:'comment-multiple-outline' },
                            component: () =>  import('@/pages/PersonalDashboard/TalkingPoints')
                        },{
                            path: 'Media/:id?',
                            name: 'Media',
                            meta: { catName: 'Media Clipping', icon:'newspaper-variant-multiple-outline' },
                            component: () =>  import('@/pages/PersonalDashboard/Media')
                        },{
                            path: 'EYEvents/:id?',
                            name: 'EYEvents',
                            meta: { catName: 'EY Events', icon:'calendar-clock' },
                            component: () =>  import('@/pages/PersonalDashboard/EYEvents')
                        },{
                            path: 'Wins',
                            name: 'Wins',
                            meta: { catName: 'Wins', icon:'trophy-award' },
                            component: () =>  import('@/pages/PersonalDashboard/Wins')
                        },{
                            path: 'CommAndPodcast',
                            name: 'CommAndPodcast',
                            meta: { catName: 'Comms', icon:'podcast' },
                            component: () =>  import('@/pages/PersonalDashboard/CommAndPodcast')
                        },{
                            path: 'TipsAndDocs',
                            name: 'TipsAndDocs',
                            meta: { catName: 'Tips & Docs', icon:'podcast' },
                            component: () =>  import('@/pages/PersonalDashboard/TipsAndDocs')
                        }
                    ]
                },
            ]
        },

        {
            path: '/preview',
            name: 'preview',
            component: () => { return import('@/layouts/fullscreen') },
            children:[
                {
                    path: 'preview',
                    name: 'preview',
                    meta: { breadCrumb: 'Summary ' },
                    component: () => import('@/pages/Preview'),
                },
            ]
        },
        {
            path: '/loadSettings',
            name: 'loadSettings',
            component: () => { return import('@/pages/loadSettings') }
        }



    ])
];