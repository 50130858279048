
export default function colorByExtension(element) {
    let result = '';
    let file = element.split('.')
    switch (file[file.length-1].toLowerCase()) {
        case 'pdf':
            result += 'bg-orange-15 text-orange';
            break;
        case 'jpg':
        case 'jpeg':
        case 'png':
            result += 'bg-violet_30 text-violet';
            break;
        case 'avi':
        case 'mpeg':
        case 'mpg':
        case 'asf':
        case 'mp4':
            result += 'bg-purple-30 text-putple';
            break;
        case 'xls':
        case 'xlsx':
            result += 'bg-green-30 text-green';
            break;
        case 'ppt':
        case 'pptx':
            result += 'bg-gold-30 text-gold';
            break;
        case 'doc':
        case 'docx':
        case 'txt':
            result += 'bg-success-30 text-success';
            break;
        case 'zip':
            // return 'bg-'
            break;
        default:
            // return 'bg-'
            break;
    }
    return result
}