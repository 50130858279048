
export default function typeNotification(key) {
    switch (key) {
        case 'serious':
            return 'text-danger'
            break;
        case 'medium':
            return 'text-warning'
            break;
        case 'mild':
            return 'text-green-dark'
            break;
    }
}