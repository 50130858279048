<template>
    <highcharts  class="chart-wrap" :options="options" :ref="chartId" :id="chartId"></highcharts>
</template>

<script>
    import Highcharts from 'highcharts'
    let gradients = [
        {
            linearGradient: {x1: 0,x2: 1,y1: 0,y2: 0},
            stops: [[0, 'rgba(49, 178, 200, 0.76)'],[1, 'rgba(153, 97, 214, 0.76)']]
        },{
            linearGradient: {x1: 0,x2: 1,y1: 0,y2: 0},
            stops: [[0, '#F9B254'],[1, '#E56E78']]
        }   
    ]

    export default {
        props: ['data','chartId','small','reflow'],
        data: function(){
            return {
                name:['mtd', 'mtd-1'],
                options: {
                    chart: {
                        type: 'areaspline',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingLeft: 0,
                        spacingRight: 0,
                        spacingTop: 10,
                    },

                    defs: {
                        glow: {
                            tagName: 'filter',
                            id: 'glow',
                            opacity: 0.5,
                            children: [{
                                tagName: 'feGaussianBlur',
                                result: 'coloredBlur',
                                stdDeviation: 2.5
                            }, {
                                tagName: 'feMerge',
                                children: [{
                                    tagName: 'feMergeNode',
                                    in: 'coloredBlur'
                                }, {
                                    tagName: 'feMergeNode',
                                    in: 'SourceGraphic'
                                }]
                            }]
                        }
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    credits:{
                        enabled: false
                    },
                    xAxis: [{
                        type: 'datetime',
                        lineColor: 'rgba(255,255,255,0)',
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: false,
                            style:{
                                color:'#768194' 
                            }
                        },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 2 
                    },{
                        type: 'datetime',
                        lineColor: 'rgba(255,255,255,0)',
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: false,
                            style:{
                                color:'#768194' 
                            }
                        },
                        tickWidth: 0,
                        // tickInterval: 1000 * 60 * 60 * 24 * 2 
                    }],
                    exporting:{
                        enabled:false
                    },
                    yAxis: {
                        title: {
                            text: '',
                        },

                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: false,
                            style:{
                                color:'#768194' 
                            },
                        }
                    },
                    tooltip: {
                        enabled:false
                    },
                    plotOptions: {
                        areaspline:{

                            fillOpacity:.3,
                            lineWidth:this.small ? 2 : 1.5,
                            marker:{
                                enabled:false
                            },
                            dataLabels:{
                                enabled: false,
                                formatter: function() {
                                    var dataMax = this.series.dataMax;
                                    if (this.y === dataMax && this.y != 0) {
                                        return this.y;
                                    }
                                },
                                crop: false,
                                overflow: 'none',
                                style:{
                                    fontSize:this.small ? "10px" :"13px",
                                    fontWeight:"bold",
                                    textOutline:"0px",
                                }
                            }

                        },
                        spline:{
                             marker:{
                                enabled:false
                            },
                        },
                        series:{
                            shadow:{
                                color:'rgba(0, 0, 0, .3)',
                                offsetX:0,
                                offsetY:-2,
                                width:12
                            },
                        }
                    },
                    legend: {
                        enabled: true,
                        verticalAlign: 'top',
                        itemStyle:{
                            color:'#ffffff'
                        },
                        y:-10

                    },
                    colors:[this.tailwindColors.pink.default,this.tailwindColors.aux.default ],
                    series: [
                    ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                var i = 0
                for(var serie in this.data){
                    let getSerie = chart.get(serie);
                    if(getSerie){
                        chart.series[i].setData(this.data[serie])  
                    } else {
                        chart.addSeries({ 
                            fillColor: {
                                linearGradient:  {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, Highcharts.color('#814CD6').setOpacity(.7).get('rgba')],
                                    [1, Highcharts.color('#814CD6').setOpacity(.05).get('rgba')]
                                ]
                            },

                            xAxis: i,
                            type: i == 1 ? 'spline' : 'areaspline',
                            //color:gradients[i],
                            id:serie,
                            name:this.name[serie],
                            data:this.data[serie],
                        })
                    }
                    i++;
                }
            },
        },
        watch: {
            data: {
                handler(a, b){
                    this.load();
                },
                deep: true
            },
            reflow(n,o){
                let chart = this.$refs[this.chartId].chart;
                chart.reflow()
            }
        },
        mounted() {
            this.load();
        }
    };
</script>
