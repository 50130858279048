
export default function iconByExtension(element) {

    let result;
    let file = element.split('.')
    switch (file[file.length-1].toLowerCase()) {
        case 'txt':
            result = ' mdi-file-document'
            break;
        case 'pdf':
            result = ' mdi-file-pdf'
            break;
        case 'jpg':
        case 'jpeg':
        case 'png':
            result = ' mdi-file-image'
            break;
        case 'avi':
        case 'mpeg':
        case 'mpg':
        case 'asf':
        case 'mp4':
            result = ' mdi-file-video'
            break;
        case 'xls':
        case 'xlsx':
            result = ' mdi-file-excel'
            break;
        case 'ppt':
        case 'pptx':
            result = ' mdi-file-powerpoint'
            break;
        case 'doc':
        case 'docx':
            result = ' mdi-file-word'
            break;
        case 'zip':
            result = ' mdi-zip-box'
            break;
        default:
            result = ' mdi-file'
            break;
    }
    return result
}