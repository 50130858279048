<template>

    <div class="chart-wrap relative" ref="container-chart">
        <highcharts :options="options" ref="gaugeChart" class="chart-component h-full w-full" />
        <div class="center-absolute" v-if="$slots.default"><slot /></div>
    </div>

</template>

<script>
    import { reduceBigNumbers } from '../main.js'


    export default {
        props: ['data', 'limit', 'color'],
        data() {
            return {
                options: {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        margin: [0, 0, 0, 0],
                    },
                    credits:{ enabled: false },
                    legend: { enabled:false },
                    title: { text: ''},
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                            outerRadius: '99%',
                            innerRadius: '92%',
                            backgroundColor:'#292F35',
                            borderWidth: 0
                        }, 
                         //{ // Track for Exercise
                         //outerRadius: '90%',
                         //backgroundColor:'#1A1919',
                         //innerRadius: '85%',
                         //borderWidth: 0
                         //}
                        ]
                    },
                    tooltip: {
                        enabled: false
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: [],
                        plotLines: [],
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: { enabled: false },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },
                    series: [
                        {
                            name: '',
                            data: [
                                { y:null, radius: '99%', innerRadius: '92%', color: '#2363C9' },
                                { y:null, radius: '99%', innerRadius: '92%', color: this.color ? this.color : '#59BEB6' }
                            ]
                        }
                    ]
                }
            }
        },
        mounted() {
            this.updateChart();     
            if (this.limit) { this.updateLimit() }
            setTimeout(() => {
                this.chart.redraw();
            }, 300);
        },
        methods: {
            updateChart() {
                if (this.data[0]) { this.chart.series[0].points[0].update(this.data[0]) }
                if (this.data[1]) { this.chart.series[0].points[1].update(this.data[1]) }
            },
            updateLimit() {
                this.chart.yAxis[0].removePlotLine('limit')
                this.chart.yAxis[0].addPlotLine({ 
                    color: this.tailwindColors.warning.default, 
                    width: 3, 
                    value: this.limit,
                    zIndex: 10,
                    id: 'limit'
                })
            }
        },
        computed: {
            chart() { return this.$refs['gaugeChart'].chart }
        },
        watch: {
            data(n, o) {
                if (JSON.stringify(n) !== JSON.stringify(o)) {

                    this.updateChart();

                    setTimeout(() => {
                        this.chart.redraw();
                    }, 100);
                    
                }
            },
            limit(n, o) {
                if (JSON.stringify(n) !== JSON.stringify(o)) { this.updateLimit() }
            }
        },
    }

</script>

<style lang="scss">
    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
        .highcharts-container {
            height: 100% !important;
            width: 100% !important;
        }
    }
    .chart-wrap > .chart-component {
        height: 100% !important;
        width: 100% !important;
    }

</style>
