
export default function colorSocialNetwork(social) {
    switch (social) {
        case 'instagram':
            return '#ff7a92'
            break;
        case 'twitter':
            return '#BEE3F8'
            break;
        case 'linkedin':
            return '#3182CE'
            break;
    }
}