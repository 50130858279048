<template>
    <div class="separator" :class="direction"></div>   
</template>
<script>

    export default {
        props:{
            /**
            * The direction of separator
            * @values horizontal, vertical
            */
            direction: {
                type: String,
                default: 'horizontal'
            },
        }
    }
</script>
<style lang="scss">
    .separator {
        &.vertical {
            border-left: 1px solid #141517;
            border-right: 1px solid #323d43;
            @apply h-full w-0;
        }
        &.horizontal {
            border-top: 1px solid #141517;
            border-bottom: 1px solid #323d43;
            @apply w-full h-0;
        }

    }
</style>