
export default function colorsByOptionCommsAndDash(type) {
    switch (type) {
        case 'TheEssential':
            return 'warning'
            break;
        case 'EYEvents':
            return 'purple'
            break;
        case 'Alerts':
            return 'danger'
            break;
        case 'TalkingPoints':
            return 'blue'
            break;
        case 'Media':
            return 'orange'
            break;
        case 'Wins':
            return 'green'
            break;
        case 'CommAndPodcast':
            return 'violet'
            break;
        case 'TipsAndDocs':
            return 'success'
            break;
    }
}