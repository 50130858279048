<template>

    <div class="h-full w-full relative">

        <loader :loading="loading" />

        <div class="margin-progress">
            <div class="margin-progress-bar" :style="'height:' + controle(selfMargin) + '%;'"></div>
            <div class="objective-wrapper">
                <div class="objective-line"
                     :style="'bottom:' + controle(objective) + '%;transform:translateY(' + controle(objective) + '%)'">
                </div>
            </div>
        </div>

        <div class="average-data"
             :style="'bottom:' + controle(averageMargin) + '%;transform:translateY(' + controle(averageMargin) + '%)'">
            <div class="text-lg text-font-gray font-bold mb-2">Average</div>
            <div class="text-3xl  font-bold mb-2">{{ averageMargin | numberFormat(2) }}<span v-if="averageMargin!='N/A'">%</span></div>
            <div class="flex text-xs  items-center justify-end text-right ">
                <div class="text-font-gray mr-2 text-sm font-semibold">vs Avg</div>
                <div class="text-lg font-semibold"
                     :class="successOrDangerClass(vsAvg)"
                >
                  <span v-if="vsAvg>0">+</span>{{ vsAvg |numberFormat(2) }}
                </div>
            </div>
            <div class="average-data-separator-v"></div>
            <div class="average-data-separator-h"
                 :style="'bottom:' + controle(averageMargin) + '%; transform:translateY(' + controle(averageMargin) + '%)'">
            </div>
        </div>

        <div class="my-data"
             :style="'bottom:' + controle(selfMargin) + '%; transform:translateY(' + controle(selfMargin) + '%)'">
            <div class="text-lg text-font-gray font-bold mb-2">My Margin</div>
            <div class="text-3xl  font-bold mb-2">{{ selfMargin | numberFormat(2) }}%</div>
            <div class="flex text-xs items-center">
                <div class="text-font-gray mr-2 text-sm font-semibold">vs Obj</div>
                <div class="text-lg font-semibold"
                     :class="successOrDangerClass(vsObj)"
                >
                    <span class="" v-if="vsObj>0">+</span>{{vsObj |numberFormat(2)}}
                </div>
                <!--                <div class="w-6 h-1 bg-danger ml-auto"></div>-->
            </div>
            <div class="my-data-separator-v"></div>
            <div class="my-data-separator-h"
                 :style="'bottom:' + controle(selfMargin) + '%; transform:translateY(' + controle(selfMargin) + '%)'">
            </div>
        </div>

    </div>

</template>

<script>
import { state } from '@/store';

    export default {
        data() {
            return {
                averageMargin: 0,
                selfMargin: 0,
                objective: 0,
                loading: false,
                vsObj: 0,
                vsAvg: 0
            }
        },
        methods: {
            load() {
                this.loading = true;

                // if(state.rolSelected.role_type_link != 'presidencia'){
                
                    this.axios.get('projects_management/margin', {params: this.params}).then(response => {
                        this.averageMargin = response.data.data.average
                        this.selfMargin = response.data.data.margin
                        this.objective = response.data.data.objective
                        this.vsObj = response.data.data.margin_vs_objective
                        this.vsAvg = response.data.data.margin_vs_average
                    })

                // }
                
                this.loading = false

            },
            controle(num){

                if(num < 0){

                    return 0

                } else if (num > 100){

                    return 100

                } else {

                    return num

                }

            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId} }
                }
                return params
            }
        },
        watch: {
            params() { this.load() }
        },
        mounted() { this.load() }
    }
</script>

<style lang="scss">
    .margin-progress{
        @apply absolute bg-progress h-full w-4 rounded-lg ;
        left:50%;
        transform: translateX(-50%);
        .margin-progress-bar{
            @apply absolute w-full rounded-lg bg-gradient-progress h-1/2;
            bottom:0;
        }
    }
    .objective-wrapper {
        @apply h-full w-full rounded-lg overflow-hidden absolute;    
        .objective-line { @apply absolute w-full h-1 bg-good; }
    }
    .average-data {
        @apply absolute w-1/2 text-right pr-14 py-3;
        right: calc(50% - .5rem);
        .average-data-separator-v {
            @apply absolute w-1 mr-12 h-full border-r-2 border-dashed border-danger;
            top:0;
            right:0;
        }
        .average-data-separator-h {
            @apply absolute w-12 h-0 border-b-2 border-dashed border-danger;
            right:0;
        }
    }
    .my-data {
        @apply absolute w-1/2 pl-14 py-3;
        left: calc(50% - .5rem);
        .my-data-separator-v {
            @apply absolute w-1 ml-12 h-full border-l-2 border-purple;
            top:0;
            left:0;
        }
        .my-data-separator-h {
            @apply absolute w-12 h-0 border-b-2 border-purple;
            left:0;
        }
    }
</style>