<template>
    <div class="h-full w-full overflow-hidden relative">

        <div v-if="isLoadingRegister" class="absolute top-0 left-0 h-full w-full bg-body bg-opacity-50 z-30">
            <loader :loading="true"/>
        </div>

        <loader :loading="loading === true" />
        <router-view v-if="loading === false" @reset="resetFilters" />

    </div>
</template>

<script>
    import {state, actions} from '@/store'
    import MobileDetect from 'mobile-detect';
    import loader from '../components/Globals/Loader.vue';

    export default {
        name: "Layout",
        components:{
            loader
        },
        data() {
            return {
                loading: true,
                deviceDetect: new MobileDetect(window.navigator.userAgent)
            };
        },
        methods: {
            async getUserData() {
                if (state.token) {
                    try {
                        this.loading = true;

                        const response = await this.axios.get(process.env.VUE_APP_BACKEY_URL + 'user/roles');
                        actions.setUser(response.data.data)
                        // actions.setToken(response.data.data.id);

                    } catch (error) {

                        console.log(error)

                        const status = error.response.status;
                        if (status === 401) {
                            actions.resetAuth();
                            router.push({name: 'login'});
                        }

                        this.counter = 10
                        let interval = setInterval( () => {
                            if (this.counter === 0) {
                                clearInterval(interval)
                                // this.logOut()
                            }
                            else { this.counter-- }
                        }, 1000);
                        
                    } finally {
                        this.loading = false;
                    }
                }
                else { 
                    this.loading = false 
                }
            },
            resetFilters(){ 
                this.getUserData() 
            },
            detectDevice(){
                // let device = 'web';
                // if (this.deviceDetect.mobile() || this.deviceDetect.tablet()) {
                //     //ios | Android
                //     device = this.deviceDetect.os()
                // }
                // return device;
            }
        },
        computed:{
            globalParamsPretty(){ 
                return state.globalParamsPretty 
            },
            params(){ 
                return state.globalParams 
            },
            user(){ 
                return state.user 
            },
            isLoadingRegister(){
                return state.isLoadingRegister;
            },
            mobile(){
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    return "tablet";
                }
                else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                    return "mobile";
                }
                return "desktop";
            },
            seeDataAs(){
                return state.changeDataAs
            }
        },
        watch: {
            globalParamsPretty: function() {
                if (this.globalParamsPretty.rol.role_type_link === 'EP')
                    if (this.$route.name === 'ProjectsSLSMU' || this.$route.name === 'PipelineSLSMU' ||
                        this.$route.name === 'ChargeabilitySls' || this.$route.name === 'InterIntra' ||
                        this.$route.name === 'InterIntraMobile' || this.$route.name === 'Chargeability' ||
                        this.$route.name === 'ProjectsManagement' || this.$route.name === 'pipelineMobile') {
                        this.$router.push('home')
                }
            },
            seeDataAs(){

                if(this.mobile == 'desktop'){
                    
                    if(this.$route.name != 'home'){
                        this.$router.push( {name:'home', query: { seeDataAs: 'true' }} );
                    } else {
                        this.$router.go();
                    }
                }
                else if(this.mobile == 'mobile'){

                    if(this.$route.name != 'homeMobile'){
                        this.$router.push( {name: 'homeMobile'} )
                    } else {

                        this.$router.go();

                    }
                }

            }
        },
        mounted() {
            this.getUserData() ;
        },

    }

</script>