export const optionsFilterInterIntra = [
    {
        name: 'Name',
        value: 'name'
    },
    {
        name: 'Purchased',
        value: 'hour_purchased',
        direction: 'desc'
    },
    {
        name: 'Sold',
        value: 'hour_sold',
        direction: 'desc'
    }
]