<template>
    <highcharts  class="chart-wrap" :options="options" :ref="chartId" :id="chartId" />
</template>

<script>
import { numberFormat } from 'highcharts';
import { reduceBigNumbers } from '../main';
    let gradients = [
        {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#D38DDE'], [1, '#814CD6']]
        },{
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#8CEABB'], [1, '#378F7B']]
        }
    ]

    export default {
        props: ['data', 'chartId', 'colors', 'mobile', 'name'],
        data: function() {
            return {
                options: {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        spacingBottom: 0,
                        spacingTop: 0,
                    },
                    title: { text: '' },
                    accessibility: {
                        point: { valueDescriptionFormat: '{index}. From {point.from} to {point.to}: {point.weight}.' }
                    },
                    tooltip:{
                        enabled: true,
                        // nodeFormat: '{point.name}: <b>{$reduceBigNumbers(point.sum)}</b><br/>'
                        nodeFormatter: function(){
                            return '<span>' + this.name + '</span>: <b>' + numberFormat(this.sum, 0) + '</b>'
                        }
                        // formatter: function () {
                        //     return '<span class="font-semibold">' + this.point
                        // },
                    },
                    colors: this.catColors,
                    series: [
                        {
                            keys: ['from', 'to', 'weight'],
                            data: [],
                            type: 'dependencywheel',
                            name: this.name,
                            //borderWidth:5,
                            //borderColor:'#2B3236',
                            nodePadding: 0,
                            dataLabels: {
                                enabled: false,
                                color: '#fff',
                                textPath: {
                                    enabled: true,
                                    attributes: { dy: 5 }
                                },
                                distance: 10
                                //connectorShape: 'crookedLine',
                                //crookDistance: '120%',
                                //alignTo: 'connectors',
                            },
                            size: this.mobile ? '100%' : '80%'
                        },{
                            type:'pie',
                            data: [],
                            name: '',
                            zIndex: 0,
                            size: '80%',
                            slicedOffset: 0,
                            colors: ['none'],
                            borderWidth: 0,
                            dataLabels: {
                                enabled: this.mobile ? false : true,
                                connectorShape: 'crookedLine',
                                crookDistance: '130%',
                                alignTo: 'connectors',

                            }
                        }
                    ],
                    credits: { enabled:false }
                }
            }
        },
        methods: {
            load() {
                if (this.data) {
                    var response = []
                    let labels = []
                    let arr = [] 
                    let colorNumber = 0
                    for (let index = 0; index < this.data.length; index++) {
                        if(this.data[index][0] != this.data[index][1]) {
                            if (arr.includes(this.data[index][0])) {
                                let ind = arr.findIndex(i => i === this.data[index][0])
                                labels[ind].y += parseInt(this.data[index][2])
                            }
                            else {
                                labels.push({
                                    name: this.data[index][0],
                                    y: parseInt(this.data[index][2]),
                                    dataLabels: {
                                        connectorColor: this.catColors[colorNumber],
                                        color: this.catColors[colorNumber],
                                        style:{
                                            textOutline:'0px'
                                        }
                                    }
                                })
                                colorNumber++
                                arr.push(this.data[index][0])
                            }

                            // Guardamos el "TO" Index 1
                            if (arr.includes(this.data[index][1])) {
                                let ind = arr.findIndex(i => i === this.data[index][1])
                                labels[ind].y += parseInt(this.data[index][2])
                            }
                            else {
                                labels.push({
                                    name: this.data[index][1],
                                    y: parseInt(this.data[index][2]),
                                    dataLabels: {
                                        connectorColor: this.catColors[colorNumber],
                                        color: this.catColors[colorNumber],
                                        style:{
                                            textOutline:'0px'
                                        }
                                    }
                                })
                                colorNumber++
                                arr.push(this.data[index][1])
                            }

                            response.push([this.data[index][0],this.data[index][1], parseInt(this.data[index][2])])
                            console.log('response', response)
                        }
                    }
                    let chart = this.$refs[this.chartId].chart;
                    chart.series[0].setData(response)
                    chart.series[1].setData(labels)
                }
                setTimeout( ()=> { chart.redraw() },5000)
            }
        },
        computed:{

        },
        watch: {
            data: {
                handler(a, b) {
                    this.load();
                },
                deep: true
            },
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss">

    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
        .highcharts-container {
            height: 100% !important;
            width: 100% !important;
        }
    }
    .chart-wrap > .chart-component {
        height: 100% !important;
        width: 100% !important;
    }

</style>
