<template>
  <transition name="fade" enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut" mode="out-in">
      <div class="absolute bg-box-80 z-10 h-full w-full top-0 rounded-lg left-0" v-if="loading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
  </transition>
</template>

<script>
export default {
  props: ['loading']
}
</script>
<style scoped>
  .lds-ring {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    position: absolute;
    width: 3rem;
    height: 3rem;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    border: .1rem solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(19, 242, 227, 1) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }

</style>