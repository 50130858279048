export const typeDocs = [
    {
        name: 'file-pdf-outline',
        value: ['pdf'],
        class: 'bg-danger-30 text-danger',
        color: 'danger'
    },
    {
        name: 'file-excel-outline',
        value: ['xls', 'xlsx'],
        class: 'bg-green-30 text-green',
        color: 'green'
    },
    {
        name: 'file-word-outline',
        value: ['doc', 'docx'],
        class: 'bg-success-30 text-success',
        color: 'success'
    },
    // {
    //     name: 'file-document',
    //     value: ['txt'],
    //     class: 'bg-orange-30 text-white',
    //     color: 'white'
    // },
    {
        name: 'file-powerpoint-outline',
        value: ['ppt', 'pptx'],
        class: 'bg-orange-30 text-orange',
        color: 'orange',
    },
    {
        name: 'file-image-outline',
        value: ['png', 'jpg', 'jpeg'],
        class: 'bg-warning-30 text-warning',
        color: 'warning',
    },
]