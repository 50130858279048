import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

import { state, actions } from '@/store';

import axios from 'axios';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes(authGuard, guestGuard)
});

export default router;

router.beforeEach(async (to, from, next) => {

    if (to.query.hasOwnProperty('token') ) {
        await actions.setToken(to.query.token);
    }

    if (to.query.hasOwnProperty('email') ) {
        await actions.setEmail(to.query.email);
    }


    if (state.loading && process.env.VUE_APP_ENV !== 'local') {
        try {
            await accessToken();
        } catch (error) {
        }
    }

    actions.setLoading(true);
    next();
});

function beforeEnter (routes, callback) {
    return routes.map(route => {
        return { ...route, beforeEnter: callback };
    });
};

function authGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token) {
            return next({ name: 'login' });
        }

        next();
    });
};

function guestGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (state.token) {
            // return next({ name: 'home' });
            // return next({ name: 'homeMobile' });
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                return next({ name: 'homeMobile' });
            }else{
                return next({ name: 'home' });
            }
        }

        next();
    });
};

export function getNestedRoutes(path) {
    const pathList = path.split('.');
    return pathList.reduce((o, i) => {
        const route = o.find(r => r.name === i);
        return route && route.children || [];
    }, router.options.routes);
}

async function accessToken() {

    if(state.token && state.token != null){

        if(!state.isLoadingRegister){

            actions.setIsLoadingRegister(true);

            return await axios.post(process.env.VUE_APP_BACKEY_URL + 'passport/register', {}, { headers: {'Authorization': `Bearer ${ state.token }`}}).then(response => {

                if (response.data.status === "success") {
                    actions.setToken(response.data.api_token);
                }

                if(state.seeDataAs){
                    refreshUserActive();
                }

                actions.setIsLoadingRegister(false);

            }).catch(function (error) {
                
                actions.setIsLoadingRegister(false);

            });            

        }

    }
}

function refreshUserActive(){

    return axios.post(process.env.VUE_APP_BACKEY_URL + 'passport/register', {}, { headers: {'Authorization': `Bearer ${ state.seeDataAs }`}}).then(response => {

        if (response.data.status === "success") {
            actions.setSeeDataAs(response.data.api_token);
        }

        // actions.setIsLoadingRegister(false);

    }).catch(function (error) {
        
        // actions.setIsLoadingRegister(false);

    });

}
















