
export default function goToSection(update) {

    switch (update.model_type) {
        case 'TheEssential':
            this.$router.push({name: 'TheEssentialMobile'})
            break;
        case 'Wins':
            this.$router.push({name: 'WinsMobile'})
            break;
        case 'TipsAndDocs':
            this.$router.push({name: 'Tipsanddocs'})
            break;
        case 'Alerts':
            this.$router.push({name: 'AlertsMobile'})
            break;
        case 'CommAndPodcast':
            this.$router.push({name: 'CommonsPodcastsMobile'})
            break;
        case 'EYEvents':
            this.$router.push({name: 'EventsMobile'})
            break;
        case 'Media':
            this.$router.push({name: 'MediaMobile'})
            break;
    }
}