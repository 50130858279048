<template>
    <div class="chart-wrap relative" ref="container-chart">
        <highcharts :options="options" ref="bubbleChart" class="chart-component" ></highcharts>

        <div class="center-absolute" v-if="$slots.default">
            <slot />
        </div>
    </div>
</template>

<script>
import { numberFormat, reduce } from 'highcharts';
import { reduceBigNumbers } from '../main';
    export default {
        name: 'ChartBubble',
        props:['data'],
        data(){
            return {
                options: {
                    chart: {
                        type: 'bubble',
                        backgroundColor:'rgba(255,255,255,0)',
                        spacingTop:0,
                        spacingBottom:0,
                        spacingRight:0,
                        spacingLeft:0,
                    },

                    legend: {
                        enabled: false
                    },

                    title: {
                        enabled: false,
                        text: ''
                    },

                    subtitle: {
                        text: ''
                    },
                    credits:{
                        enabled: false
                    },

                    xAxis: {
                        min:12,
                        max:112,
                        lineColor: this.tailwindColors.font.gray,
                        title: {
                            text: null
                        },
                        labels: {
                            enabled:true,
                            style:{
                                color: this.tailwindColors.font.gray
                            }
                        },
                        tickWidth: 0,
                        tickInterval: 25,
                        gridLineWidth: 0
                    },

                    yAxis: {
                        min:1,
                        max:3,
                        lineColor: this.tailwindColors.font.gray,
                        title: {
                            text: '',
                        },
                        labels: {
                            enabled:false,
                        },
                        gridLineWidth: 0
                    },

                    tooltip: {
                        enabled:false,
                    },

                    plotOptions: {
                        bubble:{
                            maxSize:'95%',
                            minSize:35,
                            marker:{
                                fillOpacity:.15,
                            }

                        },
                        series: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return reduceBigNumbers(parseInt(this.point.z))
                                },
                                style:{
                                    color: 'white',
                                    textOutline:'0px contrast'
                                }
                            }
                        }
                    },
                    colors:[this.tailwindColors.pink.default],
                    series: [{
                        data: []
                    }]
                }
            }
        },
        mounted() {
            this.updateChart()
        },
        methods:{
            updateChart(){

                this.chart.series[0].setData([]);
                setTimeout( () => {
                       this.chart.series[0].setData(this.data);
                 
                },5)
                //this.chart.series[1].setData(this.data[1]);
            }
        },
        computed:{
            chart(){
                return this.$refs['bubbleChart'].chart;
            }
        },
        watch:{
            data: {
                handler(n,o){
                    this.updateChart()
                },
                deep: true
            }
        },
    }
</script>