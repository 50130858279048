<template>
    <highcharts  class="chart-wrap" :options="options" :ref="chartId" :id="chartId" />
</template>

<script>
    import { reduceBigNumbers } from '../main.js'

    let gradients = [
        {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#0ACFFE'], [1, '#495AFF']]
        },
        {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#485563'], [1, '#2C3946']]
        }   
    ]

    export default {
        props: ['data', 'chartId', 'categories', 'small', 'reflow'],
        data: function() {
            return {
                options: {
                    chart: {
                        type: 'column',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor: 'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingTop: 10,
                    },

                    defs: {
                        glow: {
                            tagName: 'filter',
                            id: 'glow',
                            opacity: 0.5,
                            children: [{
                                tagName: 'feGaussianBlur',
                                result: 'coloredBlur',
                                stdDeviation: 2.5
                            }, {
                                tagName: 'feMerge',
                                children: [{
                                    tagName: 'feMergeNode',
                                    in: 'coloredBlur'
                                }, {
                                    tagName: 'feMergeNode',
                                    in: 'SourceGraphic'
                                }]
                            }]
                        }
                    },
                    title: { text: '' },
                    subtitle: { text: '' },
                    credits: { enabled: false },
                    xAxis: [{
                        type: 'datetime',
                        //categories:this.categories,
                        lineColor: 'rgba(255,255,255,0)',
                        title: { text: null },
                        labels: {
                            enabled: true,
                            style: { color:'#768194', fontSize:'9px' }
                        },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 30 
                    },{
                        type: 'datetime',
                        //categories:this.categories,
                        lineColor: 'rgba(255,255,255,0)',
                        title: { text: null },
                        labels: {
                            enabled: false,
                            style: { color:'#768194' }
                        },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 30 
                    }],
                    exporting: { enabled:false },
                    yAxis: {
                        title: { text: '' },
                        gridLineColor: 'rgba(255,255,255,0)',
                        labels: {
                            enabled: false,
                            style: { color:'#768194' },
                        }
                    },
                    tooltip: {
                        enabled: true,
                        formatter: function () {
                            return reduceBigNumbers(this.y) + ' <small>€</small>';
                        }
                    },
                    plotOptions: {
                        column: {
                            marker: { enabled: false },
                            borderWidth: 0,
                            pointWidth: 14,
                        },
                        series: {
                            borderRadius: 3,
                            shadow: {
                                color: 'rgba(0, 0, 0, .6)',
                                offsetX: 0,
                                offsetY: 1,
                                width: 4
                            },
                        }
                    },
                    legend: { enabled: false },
                    colors:['#D38DDE'],
                    series: [ ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                var i = 0
                for (var serie in this.data) {
                    let getSerie = chart.get(serie);
                    if (getSerie) { chart.series[i].setData(this.data[serie]) }
                    else {
                        chart.addSeries({ 
                            color: gradients[i],
                            xAxis: i,
                            //color:gradients[i],
                            id: serie,
                            name: serie,
                            data: this.data[serie],
                        })
                    }
                    i++;
                }
            },
        },
        watch: {
            data: {
                handler(a, b) {
                    this.load();
                },
                deep: true
            },
            reflow(n,o) {
                let chart = this.$refs[this.chartId].chart;
                chart.reflow()
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss">

    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
        .highcharts-container {
            height: 100% !important;
            width: 100% !important;
        }
    }
    .chart-wrap > .chart-component {
        height: 100% !important;
        width: 100% !important;
    }

</style>
