<template>
    <highcharts  class="h-full w-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
</template>

<script>
    import Highcharts from 'highcharts'
    import {reduceBigNumbers} from "../main";
    let gradients = [
        {
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#D38DDE'],[1, '#814CD6']]
        },{
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#8CEABB'],[1, '#378F7B']]
        }
    ]
    let gradientsPurcashed = [
        {
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#FE694E'],[1, '#F7CB85']]
        },{
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#6511EB'],[1, '#11B0EB']]
        }
    ]

    export default {
        props: ['data','chartId','categories','small','reflow','colors'],
        data: function(){
            return {
                name:['mtd','mtd-1'],
                options: {
                    chart: {
                        type: 'column',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(255,255,255,0)',
                        margin:[10,10,25,35],
                        // height: '100%'
                    },

                    defs: {
                        glow: {
                            tagName: 'filter',
                            id: 'glow',
                            opacity: 0.5,
                            children: [{
                                tagName: 'feGaussianBlur',
                                result: 'coloredBlur',
                                stdDeviation: 2.5
                            }, {
                                tagName: 'feMerge',
                                children: [{
                                    tagName: 'feMergeNode',
                                    in: 'coloredBlur'
                                }, {
                                    tagName: 'feMergeNode',
                                    in: 'SourceGraphic'
                                }]
                            }]
                        }
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    credits:{
                        enabled: false
                    },
                    xAxis: [{
                        type: 'datetime',
                        //categories:this.categories,
                        lineColor: 'rgba(255,255,255,.1)',
                        // lineColor: 'rgba(0,0,0,0)',
                        title: {
                            text: null
                        },
                        labels: {
                            // enabled: !this.small,
                            enabled:true,
                            style:{
                                color:'#768194',
                                fontSize:'7px'
                            }
                        },
                        dateTimeLabelFormats:{
                            month: '%b',
                        },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 30 
                    }],
                    exporting:{
                        enabled:false
                    },
                    yAxis: [{
                        type: 'logarithmic',
                        title: {
                            text: '',
                        },
                        lineColor: 'rgba(255,255,255,.1)',
                        max:5000,
                        //min:0,
                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: !this.small,
                            style:{
                                color:'#768194' ,
                                
                            },
                        }
                    }],
                    tooltip: {
                        enabled: true,
                        formatter: function () {
                            return reduceBigNumbers(this.y) + ' <small>h</small>';
                        }
                    },
                    plotOptions: {
                        column:{
                            marker:{
                                enabled:false
                            },
                            borderWidth:0,
                            pointWidth:4,
                        },
                        series:{
                            borderRadius: 3,
                            shadow:{
                                color:'rgba(0, 0, 0, .6)',
                                offsetX:0,
                                offsetY:1,
                                width:4
                            },
                        }
                    },
                    legend: {
                        enabled: false,
                        verticalAlign: 'top',
                        itemStyle:{
                            color:'#ffffff'
                        },
                        y:-10

                    },
                    colors:['#D38DDE'],
                    series: [
                    ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                var i = 0
                let grad = gradients
                if(this.colors == 'purcashed') {
                    
                    grad = gradientsPurcashed
                }
                for(var serie in this.data){
                    let getSerie = chart.get(serie);
                    if(getSerie){
                        chart.series[i].setData(this.data[serie])  
                    } else {
                        chart.addSeries({ 
                            color: grad[i],
                            //xAxis: i,
                            //color:gradients[i],
                            id:serie,
                            name:this.name[serie],
                            data:this.data[serie],
                        })
                    }
                    i++;
                }
                
                setTimeout(() => {
                    chart.redraw()
                }, 500);
            },
        },
        watch: {
            data: {
                handler(a, b){
                    this.load();
                },
                deep: true
            },
            reflow(n,o){
                let chart = this.$refs[this.chartId].chart;
                chart.reflow()
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss">

    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
        .highcharts-container {
            height: 100% !important;
            width: 100% !important;
        }
    }
    .chart-wrap > .chart-component {
        height: 100% !important;
        width: 100% !important;
    }

</style>
