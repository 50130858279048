<template>
    <highcharts  class="chart-wrap h-full w-full" :options="options" :ref="chartId" :id="chartId" />
</template>

<script>
    import { reduceBigNumbers } from "../main";
    const moment = require('moment');
    // let gradients = [
    //     {
    //         linearGradient: {x1: 0,x2: 1,y1: 0,y2: 0},
    //         stops: [[0, 'rgba(49, 178, 200, 0.76)'],[1, 'rgba(153, 97, 214, 0.76)']]
    //     },{
    //         linearGradient: {x1: 0,x2: 1,y1: 0,y2: 0},
    //         stops: [[0, '#F9B254'],[1, '#E56E78']]
    //     }   
    // ]

    export default {
        props: ['data', 'chartId', 'small', 'reflow', 'names'],
        data: function() {
            return {
                options: {
                    chart: {
                        type: 'areaspline',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor: 'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingLeft: 0,
                        spacingRight: 0,
                        spacingTop: 10,
                    },
                    defs: {
                        glow: {
                            tagName: 'filter',
                            id: 'glow',
                            opacity: 0.5,
                            children: [{
                                tagName: 'feGaussianBlur',
                                result: 'coloredBlur',
                                stdDeviation: 2.5
                                },
                                {
                                tagName: 'feMerge',
                                children: [{
                                    tagName: 'feMergeNode',
                                    in: 'coloredBlur'
                                    },
                                    {
                                        tagName: 'feMergeNode',
                                        in: 'SourceGraphic'
                                    }]
                            }]
                        }
                    },
                    title: { text: '' },
                    subtitle: { text: '' },
                    credits: { enabled: false },
                    xAxis: [{
                        type: 'datetime',
                        lineColor: 'rgba(255,255,255,0)',
                        title: { text: null },
                        labels: {
                            enabled: false,
                            style: { color: '#768194' }
                        },
                        tickWidth: 0,
                    },{
                        type: 'datetime',
                        lineColor: 'rgba(255,255,255,0)',
                        title: { text: null },
                        labels: {
                            enabled: false,
                            style: { color:'#768194' }
                        },
                        tickWidth: 0,
                        // tickInterval: 1000 * 60 * 60 * 24 * 2
                    }],
                    exporting: { enabled:false },
                    yAxis: {
                        max:100,
                        title: { text: '' },
                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: false,
                            style: { color:'#768194' },
                        }
                    },
                    // tooltip: {
                    //     enabled: true,
                    //     formatter: function () {
                    //         return 'Date: '+ moment(this.x).format('MMMM YYYY') + ' Amount: ' + reduceBigNumbers(this.y) + ' <small>%</small>';
                    //         //return reduceBigNumbers(this.y) + ' <small>€</small>';
                    //     }
                    // },
                    tooltip: {
                        enabled: true,
                        crosshairs: true,
                        formatter: function () {

                            let result = '<span style="color:#fff"> ' + moment(this.x).format('DD MMM') + '</span> <br>'
                            
                            for (let index2 = this.series.chart.series.length-1; index2 >= 0; index2--) {

                                if(this.series.chart.series[index2].points[this.point.index]){
                                    result += '<br> <span style="color:' + this.series.chart.series[index2].points[this.point.index].color + '">' + this.series.chart.series[index2].name + ': <b style="color:' + this.series.chart.series[index2].points[this.point.index].color + '; font-weight: bold;">' + reduceBigNumbers(this.series.chart.series[index2].points[this.point.index].y,0) + '%</b> </span>'
                                }

                            }

                            return result

                        },
                        backgroundColor: '#283143',
                        borderColor: 'rgba(0,0,0,0)'
                    },
                    plotOptions: {
                        areaspline: {
                            fillOpacity: .3,
                            lineWidth: this.small ? 2 : 1.5,
                            marker: { enabled: false },
                            dataLabels: {
                                enabled: false,
                                formatter: function() {
                                    const dataMax = this.series.dataMax;
                                    if (this.y === dataMax && this.y !== 0) {
                                        return this.y;
                                    }
                                },
                                crop: false,
                                overflow: 'none',
                                style: {
                                    fontSize: this.small ? "10px" :"13px",
                                    fontWeight: "bold",
                                    textOutline: "0px",
                                }
                            }
                        },
                        spline: {
                            marker: { enabled: false },
                        },
                        series: {
                            shadow: {
                                color: 'rgba(0, 0, 0, .3)',
                                offsetX: 0,
                                offsetY: -2,
                                width: 12
                            }
                        }
                    },
                    legend: { enabled: false },
                    colors: [this.tailwindColors.aux.default, this.tailwindColors.pink.default ],
                    series: [
                        {
                            name: 'objetive',
                            type: 'spline',
                            y: []
                        },
                            {
                                name: 'amount', 
                                y: []
                            },
                    ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                let i = 0;
                for (const serie in this.data) {
                    let getSerie = chart.get(serie);
                    if (getSerie) { getSerie.setData(this.data[serie]) }
                    else {
                        if (i === 0) {
                            chart.addSeries({
                                xAxis: i,
                                type: i === 1 ? 'areaspline' : 'spline',
                                id: serie,
                                name: this.names[i],
                                data: this.data[serie],
                            })
                        }
                        else {
                            chart.addSeries({
                                xAxis: i,
                                type: i === 1 ? 'areaspline' : 'spline',
                                id: serie,
                                name: this.names[i],
                                data: this.data[serie],
                            })
                        }
                    }
                    i++;
                }
            }
        },
        watch: {
            data: {
                handler(a, b){
                    this.load();
                },
                deep: true
            },
            reflow(n,o) {
                let chart = this.$refs[this.chartId].chart;
                chart.reflow()
            }
        },
        mounted() {
            this.load();
        }
    };
</script>
