import { actions } from '@/store';

export default function setColorsBySector(elements) {
    let i = 0
    let colorObj = {}
    for (let sector in elements) {
        actions.toggleSlSelectedItems(elements[sector].id)
        colorObj[elements[sector].id] = {
            color: this.catColors[i],
            name: elements[sector].name
        }
        i++;
    }
    actions.setSlLegendColors(colorObj)
}