const _ = require('lodash')
const plugin = require('tailwindcss/plugin')

module.exports = plugin(function({ addUtilities, e, theme, variants }) {
    const pills = theme('pills', {})
    const pillsVariants = variants('pills', [])

    const utilities = _.map(pills, ([color], name) => {

        let colorTrans = hexToRgb(color,.08)
        let colorTransHover = hexToRgb(color,.5)
        let colorTransActive = hexToRgb(color,.3)
        
        return  {
            [`.pill-${e(name)}`]: {
                color: color,
                padding: '.5rem 1rem',
                borderRadius: '0.75rem',
                backgroundColor: colorTrans,
                fontWeight:600,
                cursor:'pointer',
                textAlign:'center'
            },
            [`.pill-${e(name)}:hover`]: {
                backgroundColor: colorTransHover,
            },
            [`.pill-${e(name)}.active`]: {
                backgroundColor: colorTransActive,
            }
        }
    })
    addUtilities(utilities, pillsVariants)
})


function hexToRgb(hex,opacity = 1) {
    hex = hex.replace('#','');
    let r = parseInt(hex.substring(0,2), 16);
    let g = parseInt(hex.substring(2,4), 16);
    let b = parseInt(hex.substring(4,6), 16);
    var ret = 'rgba('+r + ',' + g + ',' + b + ','+ opacity+')'
    return ret;
}
