export default function colorByNotificationType(key) {
    switch (key) {
        case 'serious':
            return 'text-danger'
            break;
        case 'medium':
            return 'text-warning'
            break;
        case 'mild':
            return 'text-success'
            break;
    }
}