
export default function typeModel(key) {
    switch (key) {
        case 'EYEvents':
            return 'EY Events'
            break;
        case 'CommAndPodcast':
            return 'Comms'
            break;
        case 'Media':
            return 'Media Clipping'
            break;
        case 'TheEssential':
            return 'The Essential'
            break;
        case 'Wins':
            return 'Wins'
            break;
        case 'TalkingPoints':
            return 'Talking Points & Firm Docs'
            break;
        case 'Alerts':
            return 'Alerts'
            break;
        case 'TipsAndDocs':
            return 'Tips & Docs'
            break;
    }
}