<template>
    <highcharts  class="chart-wrap" :options="options" :ref="chartId" :id="chartId" />
</template>

<script>
    import {reduceBigNumbers} from "../main";
    import moment from 'moment';

    let gradients = [
        {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#8CEABB'], [1, '#378F7B']]
        },
        {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#D38DDE'], [1, '#814CD6']]
        }
    ]
    let gradientsPurcashed = [
        {
            linearGradient: { x1: 0,x2: 1, y1: 0, y2: 0 },
            stops: [[0, '#FE694E'], [1, '#F7CB85']]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [[0, '#6511EB'], [1, '#11B0EB']]
        }
    ]

    export default {
        props: ['data', 'chartId', 'categories', 'small', 'reflow', 'colors', 'marginchart', 'names'],
        data: function() {
            return {
                options: {
                    chart: {
                        type: 'column',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(255,255,255,0)',
                        spacingBottom: 0,
                        spacingTop: 0,
                        margin: this.marginchart ? [0,0,20,0] : [0,0,4,5]
                    },
                    defs: {
                        glow: {
                            tagName: 'filter',
                            id: 'glow',
                            opacity: 0.5,
                            children: [
                                {
                                tagName: 'feGaussianBlur',
                                result: 'coloredBlur',
                                stdDeviation: 2.5
                                },
                                {
                                tagName: 'feMerge',
                                children: [
                                    {
                                    tagName: 'feMergeNode',
                                    in: 'coloredBlur'
                                    },
                                    {
                                    tagName: 'feMergeNode',
                                    in: 'SourceGraphic'
                                }]
                            }]
                        }
                    },
                    title: { text: '' },
                    subtitle: { text: '' },
                    credits: { enabled: false },
                    xAxis: [{
                        type: 'datetime',
                        //categories:this.categories,
                        lineColor: 'rgba(255, 255, 255, .1)',
                        title: { text: null },
                        labels: {
                            enabled: !this.small,
                            style: { color:'#768194', fontSize:'8px' }
                        },
                        dateTimeLabelFormats: { month: '%b' },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 30 
                    },
                    {
                        type: 'datetime',
                        //categories:this.categories,
                        lineColor: 'rgba(255, 255, 255, .1)',
                        title: { text: null },
                        labels: {
                            enabled: !this.small,
                            style: { color:'#768194', fontSize:'8px' }
                        },
                        dateTimeLabelFormats: { month: '%b' },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 30 
                    }],
                    exporting: { enabled:false },
                    yAxis: {
                        title: { text: '' },
                        max: 100,
                        min: 0,
                        gridLineColor: '#1b2c34',
                        labels: {
                            enabled: !this.small,
                            style: { color:'#768194' },
                        }
                    },
                    tooltip: {
                        enabled: true,
                        crosshairs: true,
                        formatter: function () {

                            let result = '<span style="color:#fff"> ' + moment(this.x).format('DD MMM') + '</span> <br>'
                            
                            for (let index2 = this.series.chart.series.length-1; index2 >= 0; index2--) {

                                if(this.series.chart.series[index2].points[this.point.index]){
                                    result += '<br> <span style="color:' + this.series.chart.series[index2].points[this.point.index].color + '">' + this.series.chart.series[index2].name + ': <b style="color:' + this.series.chart.series[index2].points[this.point.index].color + '; font-weight: bold;">' + reduceBigNumbers(this.series.chart.series[index2].points[this.point.index].y,0) + '%</b> </span>'
                                }

                            }

                            return result

                        },
                        backgroundColor: '#283143',
                        borderColor: 'rgba(0,0,0,0)'
                    },
                    plotOptions: {
                        column: {
                            marker: { enabled:false },
                            borderWidth: 0,
                            pointWidth: 4,
                        },
                        series: {
                            borderRadius: 3,
                            shadow: {
                                color: 'rgba(0, 0, 0, .6)',
                                offsetX: 0,
                                offsetY: 1,
                                width: 4
                            }
                        }
                    },
                    legend: { enabled: false },
                    colors: ['#D38DDE'],
                    series: [ ]
                }
            }
        },
        methods: {
            load() {

                let chart = this.$refs[this.chartId].chart;
                let i = 0;
                let grad;

                if(this.colors === 'purcashed'){
                    grad = gradientsPurcashed;
                } else {
                    grad = gradients;
                }

                for (const serie in this.data) {
                    let getSerie = chart.get(serie);
                    if (getSerie) { chart.series[i].setData(this.data[serie]) }
                    else {
                        chart.addSeries({
                            color: grad[i],
                            id: serie,
                            name: this.names[i],
                            data: this.data[serie],
                            xAxis: i
                        })
                    }
                    i++;
                }
            },
        },
        watch: {
            data: {
                handler(a, b) {
                    this.load();
                },
                deep: true
            },
            reflow(n,o) {
                let chart = this.$refs[this.chartId].chart;
                chart.reflow()
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss">

    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
        .highcharts-container {
            height: 100% !important;
            width: 100% !important;
        }
    }
    .chart-wrap > .chart-component {
        height: 100% !important;
        width: 100% !important;
    }

</style>