export default function iconByNotification(key) {
    switch (key) {
        case 'serious':
            return 'text-danger mdi mdi-alert text-sm'
            break;
        case 'medium':
            return 'text-warning mdi mdi-reload-alert text-sm'
            break;
        case 'mild':
            return 'text-success mdi mdi-alert-circle-outline text-sm'
            break;
    }
}