import Vue from 'vue';
import moment from 'moment'
import _ from 'lodash';
import { toCookie, toLocal } from './storages';

const store = {
    token: null,
    user: null,
    email: null,
    loading: false,
    employees: [],
    selectedRol: {},
    params: {},
    slLegendColors: [],
    slSelectedItems: [],
    rolesParams: {},
    globalParams: false,
    globalParamsPretty: {},
    defaultTimePeriod: {
            //    start: moment().subtract(1, 'week').startOf('isoWeek'),
            //    end:  moment().subtract(1, 'week').endOf('isoWeek'),
    },
    pipelinemobile:null,
    interIntraSL:null,
    commentsListen:false,
    refreshPersonalDashboard:30000,
    isLoadingRegister: false,
    rolSelected:false,
    periodSelected:false,
    seeDataAs:false,
    changeDataAs: false,
    reloadHome: false
};

toCookie(store.slLegendColors);
const state = Vue.observable(store);

const actions = {
    setToken(token) { state.token = token },
    setUser(user) { state.user = user },
    setEmail(email) { state.email = email },
    setLoading(loaded) { state.loading = loaded },
    resetAuth() {
        state.token = null;
        state.user = null;
        state.seeDataAs = null;
    },
    setSelectedRol(rol) { state.selectedRol = rol },
    setGlobalParams(params) { state.globalParams = params },
    setGlobalParamsPretty(params) { state.globalParamsPretty = params },
    setRolesParams(params) { state.rolesParams = params },
    setSlLegendColors(colors) { state.slLegendColors = colors },
    toggleSlSelectedItems(slName) {
        if (state.slSelectedItems.includes(slName)) {
            state.slSelectedItems = state.slSelectedItems.filter(item => item !== slName)
        }
        else { state.slSelectedItems.push(slName) }
    },
    addSlSelectedItems(slName) {
        state.slSelectedItems.push(slName);
    },
    clearSlSelectedItems() { state.slSelectedItems = [] },
    setPipelineMobile(pipelinemobile){
        state.pipelinemobile = pipelinemobile
    },
    setInterIntraSL(interIntraSL){
        state.interIntraSL = interIntraSL
    },
    setDefaultTimePeriod(defaultTimePeriod){
        state.defaultTimePeriod = defaultTimePeriod
    },
    setCommentsListen(commentsListen){
        state.commentsListen = commentsListen
    },
    setIsLoadingRegister(isLoadingRegister){
        state.isLoadingRegister = isLoadingRegister
    },
    setRolSelected(rolSelected){
        state.rolSelected = rolSelected
    },
    setPeriodSelected(periodSelected){
        state.periodSelected = periodSelected
    },
    setSeeDataAs(seeDataAs){
        state.seeDataAs = seeDataAs
    },
    setChangeDataAs(){
        state.changeDataAs = !state.changeDataAs
    },
    setReloadHome(reloadHome){
        state.reloadHome = reloadHome
    }
};

export { state, actions };