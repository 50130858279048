export default function alertsCount(alerts) {
    let serious,  medium,  mild;
    serious = medium = mild = 0
    for (let index = 0; index < alerts.length; index++) {
        switch (alerts[index].notification_type) {
            case 'serious':
                serious++
                break;
            case 'medium':
                medium++
                break;
            case 'mild':
                mild++
                break;
        }
    }
    return { serious, medium, mild }
}